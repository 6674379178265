import React from "react";
import PropTypes from "prop-types";
import HeaderProfile from "./HeaderProfile/HeaderProfile";

const HeaderProfileBar = (props) => {
  return <HeaderProfile isLoggedIn={props.isLoggedIn} />;
};

HeaderProfileBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  children: PropTypes.node,
};

export default HeaderProfileBar;
