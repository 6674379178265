import styled from "styled-components";
import { FormHelperText, Select } from "@mui/material";
import { ReactComponent as DownArrow } from "../../../assets/images/svg/down-arrow.svg";

const CustomSelectFieldStyled = styled(Select)`
  ${(props) =>
    props.selectedValueFlag
      ? `color: ${props?.theme?.colors?.black};`
      : `color: ${props?.theme?.colors?.placeholderText};`}
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }
  width: ${(props) => (props?.width ? props?.width : "100%")};
`;

export const CustomFormHelperText = styled(FormHelperText)`
  position: absolute;
  top: 100%;
  color: ${(props) => props?.theme?.colors?.errorRed};
  font-size: 14px;
`;

export default CustomSelectFieldStyled;

export const DownArrowStyled = styled(DownArrow)`
  margin-top: 5px;
  margin-right: 20px;
`;

export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};
