import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomSelectFieldStyled, {
  CustomFormHelperText,
  DownArrowStyled,
  menuProps,
} from "./CustomSelectField.styled";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomSelectField = (props) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (props?.value) setSelectedValue(props?.value);
  }, []);

  const handleChange = (event) => {
    let id = event?.target?.value;
    let item = props?.items?.find((x) => x.id === id);
    setSelectedValue(item);
    props.passValue(item);
  };

  const isError =
    props.formik?.touched[props.name] &&
    !!props.formik?.errors[props.name] &&
    !props.value;

  return (
    <>
      <CustomSelectFieldStyled
        name={props?.name}
        value={selectedValue?.name ?? null}
        displayEmpty
        onChange={handleChange}
        width={props.width}
        error={
          props?.helperText ||
          (props.formik?.touched?.[props?.name] &&
            props.formik?.errors?.[props?.name])
        }
        renderValue={(selected) => {
          if (!selected) {
            return <label>{props.placeholder}</label>;
          }
          return selected;
        }}
        IconComponent={DownArrowStyled}
        MenuProps={menuProps}
      >
        {props?.items?.map((item) => {
          return (
            <MenuItem key={item.id} value={item?.name}>
              {item.name}
            </MenuItem>
          );
        })}
      </CustomSelectFieldStyled>
      {isError && (
        <CustomFormHelperText>
          {t("register.inputRequired")}
        </CustomFormHelperText>
      )}
    </>
  );
};

CustomSelectField.propTypes = {
  helperText: PropTypes.any,
  formik: PropTypes.any,
  name: PropTypes.string,
  width: PropTypes.string,
  items: PropTypes.any,
  value: PropTypes.any,
  passValue: PropTypes.func,
  placeholder: PropTypes.string,
};

CustomSelectField.defaultProps = {};

export default CustomSelectField;
