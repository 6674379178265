import styled from "styled-components";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";

export const StyledTableHead = styled(TableHead)`
  display: flex;
  flex-direction: row;
`;

export const StyledTableRow = styled(TableRow)``;

export const StyledTableCell = styled(TableCell)`
  padding-left: 30px;
  min-width: 250px;
  border: none;
`;

export const TableTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
