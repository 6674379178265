import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  Overlay,
  Modal,
  Title,
  ActionsContainer,
  ActionButton,
  CancelActionButton,
  UpperContainer,
  LowerContainer,
} from "./LicenceAddEditModal.styled";
import {
  InputFieldContainer,
  InputFieldsContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import { VARIANT } from "constants/buttonConstants";
import StartDateField from "./InputFields/StartDateField";
import EndDateField from "./InputFields/EndDateField";
import TypeDropdownField from "./InputFields/TypeDropdownField";
import { licenseParams } from "initialValues/licenseParams";
import { useValidationSchema } from "validations/licenseValidation";
import { useAddLicenseMutation, useUpdateLicenseMutation } from "features/clientLicense/clientLicenseSlice";
import { makeToastMessage } from "util/toastMessage";

const LicenseEditAddModal = (props) => {
  const { t } = useTranslation();
  const [addLicense] = useAddLicenseMutation();
  const [updateLicense] = useUpdateLicenseMutation();

  const setDateWithCurrentTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    date.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
    return date.toISOString();
  };

  const handleSubmit = async () => {
    console.log(formik.values);
    const data = {
      startDateUtc: setDateWithCurrentTime(formik.values.startDate),
      endDateUtc: setDateWithCurrentTime(formik.values.endDate),
      licenseType: formik.values.type,
      // TODO: get cliendId from selected client when page for client editing is done and this is connected to it
      clientId: "9168A9A9-7DD6-4C74-84A5-C4E513C2396F"
    };

    let response = null;

    if (props?.selectedItem) {
      response = await updateLicense({
        id: props?.selectedItem?.id,
        data,
      });
    } else {
      response = await addLicense(data);
    }

    if (response?.error) {
      alert(response.error.message);
    } else {
      makeToastMessage(
        props?.selectedItem
          ? t("superAdmin.table.licenses.successfullyUpdated")
          : t("superAdmin.table.licenses.successfullyAdded")
      );
      props.onAction();
    }
  };

  const editFlag = useMemo(() => props?.selectedItem != null, [props?.selectedItem]);

  const formik = useFormik({
    initialValues: licenseParams(props?.selectedItem),
    validationSchema: useValidationSchema(),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Overlay>
      <Modal>
        <UpperContainer>
          <Title>
            {editFlag
              ? t("superAdmin.table.licenses.editLicense")
              : t("superAdmin.table.licenses.addNewLicense")}
          </Title>
          <InputFieldsContainer>
            <InputFieldContainer>
              <StartDateField
                formik={formik}
                label="superAdmin.table.licenses.startDate"
                name="startDate"
                onChange={formik.handleChange}
                helperText={formik.errors.startDate}
              />
            </InputFieldContainer>
            <InputFieldContainer>
              <EndDateField
                formik={formik}
                label="superAdmin.table.licenses.endDate"
                name="endDate"
                onChange={formik.handleChange}
                helperText={formik.errors.endDate}
              />
            </InputFieldContainer>
            <InputFieldContainer>
              <TypeDropdownField
                formik={formik}
                label="superAdmin.table.licenses.selectLicenseType"
                name="type"
                onChange={formik.handleChange}
                helperText={formik.errors.type}
              />
            </InputFieldContainer>
          </InputFieldsContainer>
        </UpperContainer>
        <LowerContainer>
          <ActionsContainer>
            <CancelActionButton
              variant={VARIANT.OUTLINED}
              onClick={props.onCancel}
              value={t("common.cancel")}
            />
            <ActionButton
              onClick={formik.handleSubmit}
              value={editFlag ? t("common.save") : t("common.add")}
            />
          </ActionsContainer>
        </LowerContainer>
      </Modal>
    </Overlay>
  );
};

LicenseEditAddModal.propTypes = {
  onCancel: PropTypes.func,
  onAction: PropTypes.func,
  selectedItem: PropTypes.object,
};

export default LicenseEditAddModal;