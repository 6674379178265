import React from "react";
import Table from "../../components/Table/Table";
import { useGetPaginatedServiceCategoriesQuery } from "features/serviceCategory/serviceCategorySlice";
import serviceCategoriesTableConstants from "constants/tableConstants/serviceCategoriesTableConstants";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setServiceCategoryModal } from "features/modal/modalSlice";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { ServicesIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";

const SuperAdminServiceCategoriesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mapRowDataFunction = (rowData) => {
    let newData = rowData.data?.serviceCategories?.map?.((serviceCategory) => {
      return {
        ...serviceCategory,
      };
    });
    return newData;
  };

  return (
    <MainLayout>
      <PageHeader
        leftIcon={<ServicesIconSelected />}
        pageTitle={t("superAdmin.table.tableHeaderForServiceCategories")}
        buttonText={t(
          "superAdmin.table.serviceCategories.addButtonForServiceCategories"
        )}
        onClick={() => dispatch(setServiceCategoryModal(null))}
        isAddButtonShown={true}
        isFilterButtonShown={false}
      />
      <Table
        mapRowDataFunction={mapRowDataFunction}
        tableHeaders={serviceCategoriesTableConstants}
        usePaginatedQuery={useGetPaginatedServiceCategoriesQuery}
        t={t}
        rowBtnClick={(serviceCategory) =>
          dispatch(setServiceCategoryModal(serviceCategory))
        }
      />
    </MainLayout>
  );
};

SuperAdminServiceCategoriesPage.propTypes = {
  theme: PropTypes.any,
};

export default SuperAdminServiceCategoriesPage;
