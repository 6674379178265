import styled from "styled-components";
import { TableBody, TableRow, TableCell, Typography } from "@mui/material";

export const StyledTableBody = styled(TableBody)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 60vh;
  overflow: auto;
  padding-right: 20px;
`;

export const StyledTableRow = styled(TableRow)`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid ${(props) => props?.theme?.colors?.rowBorderColor};
  border-radius: 12px;
  background-color: ${(props) => props?.theme?.colors?.rowBackgroundColor};
`;

export const StyledTableCell = styled(TableCell)`
  padding-left: 30px;
  min-width: 250px;
  border: none;
  display: flex;
  align-items: center;
`;

export const LastCell = styled(StyledTableCell)`
  display: flex;
  justify-content: right;
  margin-right: 25px;
  margin-left: auto;
`;

export const StyledTypography = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
