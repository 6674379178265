import Button from "components/Button/Button";
import styled from "styled-components";

export const FilterButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 6px 32px;
  font-size: 13px;
  min-width: 100px;
  min-height: 33px;
  color: ${(props) => props?.theme?.colors?.black};
  background-color: ${(props) => props?.theme?.colors?.white};

  &:hover {
    background-color: ${(props) => props?.theme?.colors?.darkBlue};
    color: ${(props) => props?.theme?.colors?.white};

    svg {
      fill: ${(props) => props?.theme?.colors?.white};
    }
  }
`;