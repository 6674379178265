import React, { useState, useEffect } from "react";

import { Table as TableComponent } from "@mui/material";

import TableFooterControls from "./TableFooterPagination";
import { TableContainerStyled } from "./Table.styled";
import TableBodyContainer from "./TableBodyContainer";
import TableHeader from "./TableHeadContainer";
import PropTypes from "prop-types";

const Table = (props) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const { data: paginatedData } = props.usePaginatedQuery({
    pageNumber: page,
    pageSize: rowsPerPage,
    filters: props.appliedFilters,
  });

  useEffect(() => {
    if (paginatedData) {
      setRows(props.mapRowDataFunction(paginatedData));
      setTotalRows(paginatedData.totalCount);
    }
  }, [paginatedData, page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  return (
    <TableContainerStyled>
      <TableComponent>
        <TableHeader t={props.t} tableHeaders={props.tableHeaders} />

        <TableBodyContainer
          rows={rows}
          tableHeaders={props.tableHeaders}
          rowBtnClick={props.rowBtnClick}
        />
        <TableFooterControls
          page={page}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handlePageChange={handlePageChange}
          t={props.t}
        />
      </TableComponent>
    </TableContainerStyled>
  );
};

Table.propTypes = {
  tableHeaders: PropTypes.any,
  usePaginatedQuery: PropTypes.func,
  fetchFunction: PropTypes.func,
  t: PropTypes.any,
  tableFor: PropTypes.any,
  mapRowDataFunction: PropTypes.func,
  theme: PropTypes.any,
  rowBtnClick: PropTypes.func,
  appliedFilters: PropTypes.object,
};

Table.defaultProps = {
  mapRowDataFunction: (d) => d,
};

export default Table;
