import styled from "styled-components";
import { ReactComponent as ClientImg } from "../../assets/images/svg/client-validation-message.svg";
import { ReactComponent as UserImg } from "../../assets/images/svg/user-validation-message.svg";
import AuthType from "constants/authType";
import { hexToRGB } from "util/colorHelper";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  z-index: 10000;
`;

export const Modal = styled.div`
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  position: relative;
  background-color: white;
  border-radius: 24px;
  padding: 20px;
  max-width: 460px;
  aspect-ratio: 460 / 405;
  text-align: center;
  box-sizing: border-box;
`;

export const Divider = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 2px solid ${({ theme }) => hexToRGB(theme.colors.black, 0.2)};
  height: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const Title = styled.h2`
  margin: 20px;
`;

export const Message = styled.p`
  margin-top: 40px;
  text-align: left;
  min-height: 60px;
`;

export const CloseButton = styled.button`
  background-color: ${({ type, theme }) =>
    type === AuthType.CLIENT
      ? theme.colors.clientRegistration.Circle
      : theme.colors.userRegistration.Circle};
  &:hover {
    background-color: ${({ type, theme }) =>
      type === AuthType.CLIENT
        ? theme.colors.clientRegistration.Circle
        : theme.colors.userRegistration.Circle};
  }
  color: ${({ theme }) => theme.colors.textBlack};
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  cursor: pointer;
  font-weight: bold;
  width: 128px;
`;

const StyledImage = styled.img`
  width: 170px;
  height: 121px;
  display: block;
  margin: 0 auto;
`;

export const StyledClientImg = styled(StyledImage).attrs({ as: ClientImg })``;
export const StyledUserImg = styled(StyledImage).attrs({ as: UserImg })``;
