import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const clientsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedClients: builder.query({
      query: ({ pageNumber = 1, pageSize = 10, filters = {} }) => {
        const url = apiEndpoints.clients.getPaginated(pageNumber, pageSize, filters);
        return { url };
      },
    }),
  }),
});

export const {
  useGetPaginatedClientsQuery,
} = clientsApiSlice;