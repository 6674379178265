import React from "react";
import PropTypes from "prop-types";
import { FilterButtonContainer } from "./FilterButton.styled";
import { ReactComponent as DownArrowIcon } from "assets/images/svg/down-arrow.svg";
import { ReactComponent as FilterIcon } from "assets/images/svg/filter-icon.svg";
import { VARIANT } from "constants/buttonConstants";
import { useTranslation } from "react-i18next";

export const FilterButton = (props) => {
  const { t } = useTranslation();
  return (
    <FilterButtonContainer
      variant={VARIANT.CONTAINED}
      onClick={props.onClick}
      value={t(props.value)}
      startIcon={<FilterIcon />}
      endIcon={<DownArrowIcon />}
    />
  );
};

FilterButton.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
};

FilterButton.defaultProps = {
  value: "common.filter",
};

export default FilterButton;
