import React from "react";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";

const HomePage = () => {
  return (
    <MainLayout>
      <PageHeader />
    </MainLayout>
  );
};

export default HomePage;
