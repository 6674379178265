import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${(props) => props?.theme?.colors?.backgroundColor};
    //background: #D1DBDB;
  }
  * {
    font-family: Nunito !important;
  }
`;

export default GlobalStyle;
