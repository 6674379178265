import { hexToRGB } from "util/colorHelper";

export const secondaryThemeColors = {
  primaryDark: "#C4DFDF",
  secondaryDark: "#D2E9E9",
  primaryLighter: "#F8F6F4",
  secondaryLighter: "#E3F4F4",
  textColor: "#393646",
  backgroundColor: "#F3F6F9",
  purpleBoxBackgournd: "#F7FDFF",
  greenBoxBackground: "#F9FFFF",
  primaryColor: "#35CAC1",
  secondaryColor: "#87CEEB",
  secondaryColorHover: "#beeafc",
  primaryColorHover: "#8ae3da",
  completedRegistration: "#0A2323",
  uncompletedRegistration: "#658383",
  uncompletedRegistrationBackground: "#9BADAD",
  iconColor: "#FDFDFD",
  errorColor: "#FF4D4D",
  selectCountryPhoneCodeBackground: "#F3F6F9",
  fieldBorderColor: "#ccc",
  darkBlue: "#173F61",
  hoverDarkBlue: "#173F81",
  black: "#000000",
  white: "#FFFFFF",
  statusActiveBackground: "#E6F4EA",
  statusInactiveBackground: "#FDECEC",
  statusActiveText: "#4CAF50",
  statusInactiveText: "#F44336",
  statusActiveButton: "#6CAA7D",
  statusInactiveButton: "#FF4D4D",
  boxShadow: "#9BADAD",
  placeholderText: "#9C9EAA",

  googleButton: "#f4f4f4",
  googleButtonHover: "#f0f0f0",

  paginationSelected: "#b3c7d7",
  paginationHover: "#f3f6f9",

  leftAuthContentBackground: "#173F61",
  textWhite: "#FFFFFF",
  textBlack: "#171921",
  text3: "#9C9EAA",
  textUncompletedStep: "#E5E6E9",
  whiteOpacity30: hexToRGB("#FFFFFF", 0.3),
  updateTableButton: "#F98A2A",
  rowBackgroundColor: "#F3F6F9",
  rowBorderColor: "#E5E6E9",
  errorRed: "#D4342E",

  clientRegistration: {
    Circle: "#87CEEB",
  },

  userRegistration: {
    Circle: "#40E0D0",
  },
};

export const clientColors = {
  buttonColor: secondaryThemeColors.clientRegistration.Circle,
  buttonColorHover: secondaryThemeColors.clientRegistration.Circle,
  buttonTextColor: secondaryThemeColors.textBlack,
};

export const userColors = {
  buttonColor: secondaryThemeColors.userRegistration.Circle,
  buttonColorHover: secondaryThemeColors.userRegistration.Circle,
  buttonTextColor: secondaryThemeColors.textBlack,
};
