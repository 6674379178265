import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    startDate: Yup.date().required(t("superAdmin.table.licenses.startDateRequired")),
    endDate: Yup.date()
      .required(t("superAdmin.table.licenses.endDateRequired"))
      .min(Yup.ref("startDate"), t("superAdmin.table.licenses.startAndEndNotGood")),
    type: Yup.string().required(t("superAdmin.table.licenses.licenseTypeRequired")),
  });
};