import styled from "styled-components";
import {
  Typography,
  FormControl,
  TableFooter,
  Pagination,
} from "@mui/material";

export const FooterContainer = styled(TableFooter)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const StyledPagination = styled(Pagination)`
  & .MuiPaginationItem-root {
    &.Mui-selected {
      background-color: ${(props) => props?.theme?.colors?.paginationSelected};
      color: ${(props) => props?.theme?.colors?.textWhite};
    }

    &:hover:not(.Mui-selected) {
      background-color: ${(props) => props?.theme?.colors?.paginationHover};
    }
  }
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledTypography = styled(Typography)`
  color: ${(props) => props?.theme?.colors?.darkBlue};
  margin-right: 8px;
  margin-left: 8px;
`;

export const StyledSelect = styled(FormControl)`
  min-width: 60px;
  margin-left: 8px;
`;
