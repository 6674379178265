import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as ArrowDown } from "../../../../assets/images/svg/arrow-down.svg";
import PopoverComponent from "components/Popover/Popover";

export const HeaderProfileContainer = styled(Box)`
  border: 1px solid #e5e6e9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  height: 64px;
  width: ${(props) => (props?.isLoggedIn ? "215px" : "")};
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  flex-grow: 1;
  padding-right: 25px;
`;

export const StyledPopover = styled(PopoverComponent)`
  & .MuiPopover-paper {
    border-radius: 8px;
    overflow: hidden;
    padding: 16px;
  }
`;

export const FullNameTypography = styled(Typography)`
  font-size: 14;
  font-weight: 700;
  color: ${(props) => props?.theme?.colors?.black};
`;
export const RoleTypography = styled(Typography)`
  font-size: 14;
  font-weight: 500;
  color: ${(props) => props?.theme?.colors?.text3};
`;

export const ArrwoDownStyled = styled(ArrowDown)`
  margin-right: 10px;
  margin-left: auto;
  cursor: pointer;
`;
