import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Divider,
  Title,
  CloseButton,
  StyledUserImg,
  Overlay,
  Modal,
  ActionButton,
  ButtonsContainer,
} from "./ChangeUserStatusModal.styled";
import { VARIANT } from "constants/buttonConstants";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import { useChangeUserStatusMutation } from "features/user/usersApiSlice";
import { makeToastMessage, makeErrorToastMessage } from "util/toastMessage";

const ChangeUserStatusModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [changeUserStatus] = useChangeUserStatusMutation();

  const onAction = async () => {
    const response = await changeUserStatus(props.userId);
    response.data.isSuccess
      ? makeToastMessage(t("superAdmin.table.users.successfullyChangedStatus"))
      : makeErrorToastMessage(
          t("superAdmin.table.users.unsuccessfullyChangedStatus")
        );
    dispatch(unsetShownModal());
  };

  const onClose = async () => {
    dispatch(unsetShownModal());
  };

  return (
    <Overlay>
      <Modal>
        <StyledUserImg />
        <Title>
          {t("superAdmin.table.users.confirmationStatusChangeMessage", {
            action: props.active
              ? t("superAdmin.table.users.toActivate")
              : t("superAdmin.table.users.toDeactivate"),
          })}
        </Title>
        <Divider>
          <ButtonsContainer>
            <CloseButton
              onClick={onClose}
              value={t(`common.cancel`)}
              variant={VARIANT.OUTLINED}
            />
            <ActionButton
              active={props.active}
              onClick={onAction}
              value={
                props.active
                  ? t(`superAdmin.table.users.buttonDeactivate`)
                  : t(`superAdmin.table.users.buttonActivate`)
              }
            />
          </ButtonsContainer>
        </Divider>
      </Modal>
    </Overlay>
  );
};

ChangeUserStatusModal.propTypes = {
  active: PropTypes.bool,
  userId: PropTypes.string,
};

export default ChangeUserStatusModal;
