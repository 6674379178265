import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const clientLicensesApi = apiSlice.injectEndpoints({
  tagTypes: ["Table"],
  endpoints: (builder) => ({
    getAllClientLicenses: builder.query({
      query: () => ({
        url: apiEndpoints.clientLicenses.getAll,
      }),
      invalidatesTags: ["Table"],
    }),
    getPaginatedClientLicenses: builder.query({
      query: ({ pageNumber = 1, pageSize = 10 }) => ({
        url: apiEndpoints.clientLicenses.getPaginated
          .replace("{pageNumber}", pageNumber)
          .replace("{pageSize}", pageSize),
      }),
      providesTags: ["Table"],
    }),
    UpdateLicense: builder.mutation({
      query: ({ id, data }) => ({
        url: apiEndpoints.clientLicenses.updateLicence.replace("{id}", id),
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Table"],
    }),
    AddLicense: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.clientLicenses.addLicence,
        method: "POST",
        body: { ... data },
      }),
      invalidatesTags: ["Table"],
    }),
  }),
});

export const {
  useGetAllClientLicensesQuery,
  useGetPaginatedClientLicensesQuery,
  useUpdateLicenseMutation,
  useAddLicenseMutation,
} = clientLicensesApi;
