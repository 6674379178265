import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  ActionContainer,
  ImageContainer,
  InfoContainer,
  MainContainer,
  NameContainer,
  StatusContainer,
  StatusButtonContainer,
  StatusText,
  ActiveCircle,
  LongDash,
} from "./SelectedUserContainer.styled";
import { useTranslation } from "react-i18next";
import BackButton from "components/RightAuthContent/Buttons/BackButton/BackButton";
import { ReactComponent as CheckImg } from "assets/images/svg/status-active-check.svg";
import { ReactComponent as XImg } from "assets/images/svg/status-inactive-x.svg";
import { useDispatch } from "react-redux";
import { setChangeUserStatusModal } from "features/modal/modalSlice";
import { useGetUserQuery } from "features/user/usersApiSlice";
import CustomInputField from "components/InputFields/CustomFields/CustomInputField";

export const SelectedUserContainer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, isLoading } = useGetUserQuery(props?.userId);
  const user = isLoading ? null : data.data.user;

  const isActive = useMemo(() => {
    return user?.isActive;
  }, [user, isLoading]);
  const onClickStatusButton = () => {
    dispatch(setChangeUserStatusModal({ active: isActive, userId: user?.id }));
  };

  return (
    <MainContainer>
      <InfoContainer>
        <StatusContainer>
          <StatusText active={isActive}>
            {isActive
              ? t("superAdmin.table.users.labelStatusActive")
              : t("superAdmin.table.users.labelStatusInactive")}
          </StatusText>
          <StatusButtonContainer
            active={isActive}
            onClick={onClickStatusButton}
          >
            <ActiveCircle>{isActive ? <CheckImg /> : <XImg />}</ActiveCircle>
          </StatusButtonContainer>
        </StatusContainer>
        <ImageContainer></ImageContainer>
        <NameContainer>{user?.firstName + " " + user?.lastName}</NameContainer>
        <LongDash />
        <CustomInputField
          label={"common.labelEmail"}
          value={user?.email}
          disabled
        />
        <CustomInputField
          label={"common.labelPhoneNumber"}
          value={user?.phoneNumber}
          disabled
        />
        <CustomInputField
          label={"common.labelCountry"}
          value={user?.country}
          disabled
        />
        <CustomInputField
          label={"common.labelCity"}
          value={user?.city}
          disabled
        />
      </InfoContainer>
      <ActionContainer>
        <BackButton onClick={props.onClickBackBtn} />
      </ActionContainer>
    </MainContainer>
  );
};

SelectedUserContainer.propTypes = {
  userId: PropTypes.string,
  onClickBackBtn: PropTypes.func,
};

export default SelectedUserContainer;
