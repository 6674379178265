import {
  selectCurrentRoles,
  selectCurrentToken,
} from "features/auth/authSlice";
import { useMemo } from "react";
import { isExpired } from "react-jwt";
import { useSelector } from "react-redux";
import { USER_ROLES } from "constants/userRoles";
import DATA_TYPES from "constants/dataTypes";

export const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  var rolesResp = useSelector(selectCurrentRoles);
  const roles =
    rolesResp == null
      ? []
      : typeof rolesResp === DATA_TYPES.STRING
      ? [rolesResp]
      : rolesResp;

  const isLoggedIn = useMemo(() => {
    return token && isExpired(token);
  }, [token]);

  const isUser = useMemo(() => {
    return roles.includes(USER_ROLES.USER);
  }, [roles, isLoggedIn]);

  const isClient = useMemo(() => {
    return roles.includes(USER_ROLES.CLIENT_ADMIN);
  }, [roles, isLoggedIn]);

  const isSuperAdmin = useMemo(() => {
    return roles.includes(USER_ROLES.SUPER_ADMIN);
  }, [roles, isLoggedIn]);

  const hasRole = (role) => roles.includes(role);

  return {
    user: {
      id: token?.id,
      email: token?.email,
      role: token?.role,
    },
    roles,
    hasRole,
    isLoggedIn,
    isUser,
    isClient,
    isSuperAdmin,
  };
};
