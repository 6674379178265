export default {
  app: {
    title: "Service Appointments",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  logo: {
    text: "Service Appointemnts",
  },
  common: {
    finish: "Finish",
    serviceCategories: "serviceCategories",
    from: "od",
    language: "Language",
    english: "English",
    serbian: "Serbian",
    close: "Close",
    dataGridExample: "Data Grid Example",
    trademark: "TM",
    search: "Search",
    error: "Error",
    continue: "Continue",
    labelLocation: "Location",
    labelUsername: "Username",
    labelEmail: "Email",
    labelPassword: "Password",
    labelCompanyName: "Company name",
    labelPrimaryService: "Primary service",
    labelCountry: "Country",
    labelCity: "City",
    labelAddress: "Address",
    labelPhone: "Phone",
    labelPhoneNumber: "Phone number",
    labelFirstName: "First name",
    labelLastName: "Last name",
    labelPasswordConfirmation: "Password confirmation",
    labelClientName: "Client name",
    clients: "Clients",
    status: "Status",
    usernameInput: "Input username",
    email: "Input email address",
    passwordInput: "Input password",
    companyNameInput: "Input company name",
    primaryServiceSelect: "Choose primary service",
    countrySelect: "Choose country",
    citySelect: "Choose city",
    addressInput: "Input address",
    phoneInput: "Input phone",
    firstNameInput: "Input first name",
    lastNameInput: "Input last name",
    passwordConfirmationInput: "Confirm password",
    codeInput: "Input code",
    next: "Next",
    nextTablePage: "Next",
    nextPage: "Next page",
    previousTablePage: "Previous",
    previousPage: "Previous page",
    back: "Back",
    goBack: "Go Back",
    ok: "Ok",
    done: "Done",
    confirm: "Confirm",
    printDownload: "Print/Download",
    cancel: "Cancel",
    remove: "Remove",
    invite: "Invite",
    add: "Add",
    save: "Save",
    edit: "Edit",
    details: "Details",
    delete: "Delete",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    or: "Or",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} to {{end}}",
    },
    users: "Users",
    filter: "Filter",
  },
  code: {
    sentEmailMessage:
      "We have sent you a validation code to the email you previously specified. Please check your email address and input the code  in order to finish the process of registration.",
    labelSentEmailCodeInput: "Input code sent to your email",
    sentPhoneMessage:
      "We have sent you a validation code to the email you previously specified. Please check your email address and input the code  in order to finish the process of registration.",
    labelSentPhoneCodeInput: "Input code sent as SMS message",
    sendNewCode: "Send new code",
  },
  pages: {
    about: "About",
    help: "Help",
    contact: "Contact us",
    messages: "Messages",
    appointments: "Appointments",
  },
  register: {
    registerTitle: "Register",
    alreadyHaveAccount: "Already have account?",
    usernameRequired: "Username is required.",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    passwordsDoNotMatch: "The passwords do not match.",
    passwordUppercase: "The passwords must have a upper case character.",
    passwordLowercase: "The passwords must have a lower case character.",
    passwordNumber: "The passwords must have a number.",
    passwordSpecial: "The passwords must have a special character.",
    inputRequired: "Input is required.",
    usernameAlreadyTaken: "This username is already in use.",
    emailAlreadyTaken: "This email is already in use.",
    progress: {
      title: {
        client: "Register as a service provider in a few steps",
        user: "Register as a service user in a few steps",
      },
      client: {
        steps: [
          "Company information",
          "Email and password",
          "Registration confirmation",
        ],
      },
      user: {
        steps: [
          "Personal information",
          "Additional information",
          "Email verification",
        ],
      },
    },
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Don't have an account? ",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    noUsers: "There are no users with that email.",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    signUpRecommendation: "Sign up",
    email: "Please enter your email address",
    password: "Please enter your password",
    logInTitle: "Login",
    logIn: "Log In",
    signUp: "Sign Up",
    continueWithGoogle: "Continue with google",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Forgot your password?",
    forgotPasswordEmailSent:
      "If you have account email will be delivered soon.",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Forgot Password",
    label: "Send email",
    emailRequired: "An email is required.",
    emailFormat: "Invalid email address format.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  middlepage: {
    alreadyGotAccount: "Already got account? ",
    user: {
      title: "Register as a service <0>user</0>",
      text: "Do you want to quickly and easily book an appointment for the service you need?",
      button: "Register as a user",
    },
    client: {
      title: "Register as a service <0>provider</0>",
      text: "Are you providing services and need an easy way to schedule appointments?",
      button: "Register as a client",
    },
  },
  validationmessage: {
    sentCodeTitle: "New code is sent to you!",
    client: {
      title: "You have successfully registered!",
      text: "You are ready for your first clients. Track appointments and manage appointments in a simple way.",
    },
    user: {
      title: "Your registration is complete!",
      text: "Now you can easily make appointments for the desired services",
    },
    button: "Close",
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  superAdmin: {
    pageTitle: "Super admin",
    dashboardTitle: "Dashboard",
    table: {
      numberOfShowingResultsBeforeSelect: "Show",
      numberOfShowingResultsAfterSelect: "from {{total}} data",
      tableHeaderForClients: "Clients",
      tableHeaderForUsers: "Users",
      tableHeaderForServiceCategories: "Service Categories",
      tableHeaderForLicenses: "Licenses",
      editButton: "Edit",
      serviceCategories: {
        addButtonForServiceCategories: "Add new Service Category",
        editButton: "Edit",
        descriptionForServiceCategories: "Description",
        successfullyAdded: "Successfully added service category",
        successfullyUpdated: "Successfully updated service category",
      },
      clients: {
        addButtonForClients: "Add new Client",
        locationForClients: "Location",
        statusForClients: "Status",
        statusActive: "Active",
        statusInactive: "Inactive",
        filters: {
          clientNameInput: "Input client name",
          citySelect: "Choose city",
          allCities: "All cities",
          statusSelect: "Choose status",
          allStatuses: "All statuses",
          primaryServiceSelect: "Choose primary service",
          allServices: "All services",
        },
      },
      users: {
        addButtonForUsers: "Add new user",
        filters: {
          userNameInput: "Input user name",
          emailInput: "Input email",
          citySelect: "Choose city",
          allCities: "All cities",
          statusSelect: "Choose status",
          allStatuses: "All statuses",
        },
        labelStatusActive: "Active:",
        labelStatusInactive: "Inactive:",
        buttonActivate: "Activate",
        buttonDeactivate: "Deactivate",
        confirmationStatusChangeMessage:
          "Are you sure you want to {{action}} user?",
        toActivate: "activate",
        toDeactivate: "deactivate",
        successfullyChangedStatus: "Successfully changed user status",
        unsuccessfullyChangedStatus: "Unsuccessfully changed user status",
      },
      licenses: {
        addButonForLicenses: "Add new License",
        updated: "Update date",
        issuedAtUtc: "Issued at",
        expiresAtUtc: "Expires at",
        endDate: "End date",
        startDate: "Start date",
        updatedAtUtc: "Updated",
        type: "Licence type",
        selectLicenseType: "Select license type",
        chooseLicence: "Choose licence",
        licenseType: {
          trial: "Trial",
          full: "Full",
        },
        addNewLicense: "Add New License",
        editLicense: "Edit License",
        successfullyUpdated: "Licence was successfully updated",
        successfullyAdded: "Licence was successfully added",
        status: "Status",
        statusActive: "Active",
        statusInactive: "Inactive",
        startDateRequired: "Start date is required",
        endDateRequired: "End date is required",
        licenceTypeRequired: "License type is required",
        startAndEndNotGood: "End date cannot be before start date",
      },
    },
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },

  serviceCategory: {
    addNewServiceCategory: "Add new service category",
    editServiceCategory: "Edit service category",
    inputField: {
      label: {
        name: "Name",
        description: "Description",
      },
      placeholder: {
        name: "Input name",
        description: "Input description",
      },
    },
  },

  footer: {
    left: "Termium <0>Copyright</0> © <0>2024</0>",
    companyName: "Tremium Software. ",
    rights: " All rights reserved.",
  },

  sidebar: {
    controlTable: "Control Table",
    services: "Services",
    clients: "Clients",
    users: "Users",
  },
};
