import React from "react";
import { Route, Routes } from "react-router-dom";

import LoginPage from "./pages/LoginPage/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "pages/ResetPassword/ResetPasswordPage";
import AuthType from "constants/authType";
import HomePage from "pages/HomePage/HomePage";
import RequireAuth from "components/RequireAuth/RequireAuth";
import AuthCallback from "pages/AuthCallbackPage/AuthCallbackPage";
import ProfilePage from "pages/ProfilePage/ProfilePage";
import SettingsPage from "pages/SettingsPage/SettingsPage";
import Error from "pages/ErrorPage/ErrorPage";
import { PAGES } from "constants/pages";
import DashboardPage from "pages/DashboardPage/DashboardPage";
import MiddlePage from "pages/MiddlePage/MiddlePage";
import RegisterPage from "pages/RegisterPage/RegisterPage";
import SuperAdminServiceCategories from "pages/SuperAdmin/SuperAdminServiceCategoriesPage";
import SuperAdminClientLicense from "pages/SuperAdmin/SuperAdminClientLicensesPage";
import RequireNoAuth from "components/RequireNoAuth/RequireNoAuth";
import SuperAdminPageClients from "pages/SuperAdmin/SuperAdminPageClients";
import UsersPage from "pages/SuperAdmin/SuperAdminPageUsers";
import MessagesPage from "pages/Messages/MessagesPage";
import AppointemntsPage from "pages/Appointments/AppointmentsPage";
import AboutPage from "pages/About/AboutPage";
import HelpPage from "pages/Help/HelpPage";
import ContactPage from "pages/Contact/ContactPage";

const AppRoutes = () => (
  <Routes>
    <Route
      path={PAGES.BASE.route}
      element={<HomePage />}
      errorElement={<Error />}
    />
    <Route
      exact
      path={PAGES.ABOUT.route}
      element={<AboutPage />}
      errorElement={<Error />}
    />
    <Route
      exact
      path={PAGES.HELP.route}
      element={<HelpPage />}
      errorElement={<Error />}
    />
    <Route
      exact
      path={PAGES.CONTACT.route}
      element={<ContactPage />}
      errorElement={<Error />}
    />
    <Route element={<RequireNoAuth />} errorElement={<Error />}>
      <Route
        path={PAGES.MIDDLEPAGE.route}
        element={<MiddlePage />}
        errorElement={<Error />}
      />
      <Route
        path={PAGES.LOGIN.route}
        element={<LoginPage />}
        errorElement={<Error />}
      />
      <Route
        exact
        path={PAGES.REGISTER_USER.route}
        element={<RegisterPage type={AuthType.USER} />}
        errorElement={<Error />}
      />
      <Route
        exact
        path={PAGES.REGISTER_CLIENT.route}
        element={<RegisterPage type={AuthType.CLIENT} />}
        errorElement={<Error />}
      />
      <Route
        path={PAGES.RESET_PASSWORD.route}
        element={<ResetPasswordPage />}
        errorElement={<Error />}
      />
      <Route
        exact
        path={PAGES.FORGOT_PASSWORD.route}
        element={<ForgotPasswordPage />}
        errorElement={<Error />}
      />
    </Route>
    <Route path="api/auth/:provider/callback" element={<AuthCallback />} />
    <Route element={<RequireAuth />} errorElement={<Error />}>
      <Route path={PAGES.DASHBOARD.route} element={<DashboardPage />} />
      <Route path={PAGES.USERS.route} element={<UsersPage />} />
      <Route path={PAGES.CLIENTS.route} element={<SuperAdminPageClients />} />
      <Route path={PAGES.PROFILE.route} element={<ProfilePage />} />
      <Route path={PAGES.SETTINGS.route} element={<SettingsPage />} />
      <Route path={PAGES.MESSAGES.route} element={<MessagesPage />} />
      <Route path={PAGES.APPOINTMENTS.route} element={<AppointemntsPage />} />
      <Route path={PAGES.SUPERADMIN.route} element={<DashboardPage />} />

      <Route
        path={PAGES.SERVICE_CATEGORIES.route}
        element={<SuperAdminServiceCategories />}
      />
      <Route
        path={PAGES.CLIENT_LICENSES.route}
        element={<SuperAdminClientLicense />}
      />
    </Route>
  </Routes>
);

export default AppRoutes;
