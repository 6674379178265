import * as Yup from "yup";

export const ClientStepOneValidation = Yup.object().shape({
  username: Yup.string().required("register.inputRequired"),
  companyName: Yup.string().required("register.inputRequired"),
  address: Yup.string().required("register.inputRequired"),
  phoneNumber: Yup.string().required("register.inputRequired"),
  city: Yup.object().required("register.inputRequired"),
  country: Yup.object().required("register.inputRequired"),
  primaryService: Yup.object().required("register.inputRequired"),
});

export const ClientStepTwoValidation = Yup.object().shape({
  firstName: Yup.string().required("register.inputRequired"),
  lastName: Yup.string().required("register.inputRequired"),
  email: Yup.string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "register.emailFormat")
    .required("register.inputRequired"),
  password: Yup.string()
    .required("register.inputRequired")
    .min(8, "register.passwordLength")
    .matches(/[A-Z]/, "register.passwordUppercase")
    .matches(/[a-z]/, "register.passwordLowercase")
    .matches(/[0-9]/, "register.passwordNumber")
    .matches(/[\W_]/, "register.passwordSpecial"),
  confirmedPassword: Yup.string()
    .required("register.inputRequired")
    .oneOf([Yup.ref("password"), null], "register.passwordsDoNotMatch"),
});

export const ClientStepThreeValidation = Yup.object().shape({
  sentEmailCode: Yup.string().required("register.inputRequired"),
});
