export const primaryThemeColors = {
  primaryDark: "#393646",
  secondaryDark: "#4F4557",
  secondaryLighter: "#6D5D6E",
  backgroundColor: "#F3F6F9",
  primaryLighter: "#836E80",
  headerColor: "#FFFFFF",
  textColor: "#C4DFDF",
  purpleColor: "#855CE0",
  purpleBoxBackgournd: "#F7FDFF",
  greenColor: "#35C2C1",
  greenBoxBackground: "#F9FFFF",
  hoverPurpleColor: "#8E76C2",
  hoverGreenColor: "#66B5B4",
  completedRegistration: "#0A2323",
  uncompletedRegistration: "#658383",
  uncompletedRegistrationBackground: "#9BADAD",
  iconColor: "#FDFDFD",
  clientButtonColor: "#87CEEB",
  userButtonColor: "#173F61",
  lightPurpleColor: "#9966CC",
  primaryColor: "#40E0D0",
  secondaryColor: "#87CEEB",
  sidebarTextSelected: "#171921",
  sidebarText: "#ffffff",
  footerBackground: "#b3b3b3",
  darkBlue: "#173F61",
  black: "#000000",
  white: "#FFFFFF",
  statusActiveBackground: "#E6F4EA",
  statusInactiveBackground: "#FDECEC",
  statusActiveText: "#4CAF50",
  statusInactiveText: "#F44336",
  placeholderText: "#9C9EAA",
};

export const clientColors = {
  buttonColor: primaryThemeColors.purpleColor,
  buttonColorHover: primaryThemeColors.hoverPurpleColor,
};

export const userColors = {
  buttonColor: primaryThemeColors.greenColor,
  buttonColorHover: primaryThemeColors.hoverGreenColor,
};
