import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    serviceCategoryModal: false,
    serviceCategoryModalProps: {
      serviceCategory: null,
    },
    sentCodeModal: false,
    sentCodeModalProps: {
      authType: null,
    },
    finishedRegistrationModal: false,
    finishedRegistrationModalProps: {
      authType: null,
    },
    changeUserStatusModal: false,
    changeUserStatusModalProps: {
      userId: null,
      active: null,
    },
  },
  reducers: {
    setServiceCategoryModal: (state, action) => {
      return {
        ...state,
        serviceCategoryModal: true,
        serviceCategoryModalProps: {
          serviceCategory: action.payload,
        },
      };
    },
    unsetShownModal: (state) => {
      state.serviceCategoryModal = false;
      state.sentCodeModal = false;
      state.finishedRegistrationModal = false;
      state.changeUserStatusModal = false;
    },
    setSentCodeModal: (state, action) => {
      return {
        ...state,
        sentCodeModal: true,
        sentCodeModalProps: {
          authType: action.payload,
        },
      };
    },
    setFinishedRegistrationModal: (state, action) => {
      return {
        ...state,
        finishedRegistrationModal: true,
        finishedRegistrationModalProps: {
          authType: action.payload,
        },
      };
    },
    setChangeUserStatusModal: (state, action) => {
      console.log(action);

      return {
        ...state,
        changeUserStatusModal: true,
        sentCodeModalProps: {
          userId: action.payload.userId,
          active: action.payload.active,
        },
      };
    },
  },
});

export const {
  setServiceCategoryModal,
  setSentCodeModal,
  setFinishedRegistrationModal,
  setChangeUserStatusModal,
  unsetShownModal,
} = modalSlice.actions;
export default modalSlice.reducer;

export const modalSelector = (state) => state.modal;

export const selectModal = createSelector(modalSelector, (state) => {
  return state;
});
