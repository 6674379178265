import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const authSlice = createSlice({
  name: "auth",
  initialState: { user: null, token: null },
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken, refreshToken } = action.payload;
      state.user = user;
      state.token = { accessToken, refreshToken };
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;

export const authSelector = (state) => state.auth;
export const selectCurrentUser = createSelector(
  authSelector,
  (state) => state.user
);
export const selectCurrentToken = createSelector(
  authSelector,
  (state) => state.token
);
export const selectCurrentRoles = createSelector(
  authSelector,
  (state) => state.user?.role
);
