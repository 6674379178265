export const serviceCategoryParams = (serviceCategory) => {
  return {
    name: serviceCategory?.name ?? "",
    description: serviceCategory?.description ?? "",
  };
};

export const FormikParams = {
  name: "",
  description: "",
};
