export const licenseParams = (license) => {
    return {
      startDate: license?.startDate ?? "",
      endDate: license?.endDate ?? "",
      type: license?.type ?? "",
    };
  };
  
  export const FormikLicenseParams = {
    startDate: "",
    endDate: "",
    type: "",
  };
  