import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomFieldLabel from "./CustomFieldLabel";
import CustomTextField from "./CustomTextField";
import { Box } from "@mui/material";

const CustomInputField = (props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} />
      <CustomTextField value={props.value} disabled={props.disabled} />
    </Box>
  );
};

CustomInputField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
};

export default CustomInputField;
