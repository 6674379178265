import React, { useEffect, useMemo, useState } from "react";
import Table from "../../components/Table/Table";
import { useGetPaginatedFilteredUsersQuery } from "features/user/usersApiSlice";
import usersTableConstants from "constants/tableConstants/usersTableConstants";
import { useTranslation } from "react-i18next";
import Status from "components/Status/Status";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import SelectFilter from "components/Filter/SelectFilter/SelectFilter";
import { useGetAllCitiesQuery } from "features/lookup/lookupSlice";
import { InputFieldContainer } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import { userStatuses } from "constants/filters/statusConstants";
import { randomIdGenerator } from "util/randomGenerator";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { UsersIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import SelectedUserContainer from "components/SuperAdmin/SelectedUserContainer/SelectedUserContainer";

const UsersPage = () => {
  const { t } = useTranslation();

  const mapRowDataFunction = (rowData) =>
    rowData.data?.users?.map?.((user) => {
      return {
        ...user,
        name: `${user.firstName} ${user.lastName}`,
        status: (
          <Status
            isActive={user.isActive}
            activeTextKey="superAdmin.table.clients.statusActive"
            inactiveTextKey="superAdmin.table.clients.statusInactive"
          />
        ),
      };
    });

  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const saveFilters = () => {
    const [firstName, lastName] = filters?.name?.split(" ") || "";

    setAppliedFilters({
      ...filters,
      firstName,
      lastName,
      city: filters?.city?.id,
      status: filters?.status?.id,
    });
  };

  const { data, isLoading } = useGetAllCitiesQuery();
  const mapCitiesFunction = (data) =>
    data.data.cityNames.map((name) => {
      return {
        id: name,
        name,
      };
    });
  const [cities, setCities] = useState([]);
  useEffect(() => {
    if (!isLoading) setCities(mapCitiesFunction(data));
  }, [data, isLoading]);

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()} twoInputs>
        <TextFilter
          label={"common.labelFirstName"}
          placeholder={"superAdmin.table.users.filters.userNameInput"}
          onChange={(name) =>
            setFilters((prevState) => {
              return { ...prevState, name };
            })
          }
          value={filters?.name}
        />
        <TextFilter
          label={"common.labelEmail"}
          placeholder={"superAdmin.table.users.filters.emailInput"}
          onChange={(email) =>
            setFilters((prevState) => ({ ...prevState, email }))
          }
          value={filters?.email}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()} twoInputs>
        <SelectFilter
          label={"common.labelLocation"}
          placeholder={"superAdmin.table.users.filters.citySelect"}
          defaultValue={"superAdmin.table.users.filters.allCities"}
          onChange={(city) =>
            setFilters((prevState) => ({ ...prevState, city }))
          }
          value={filters?.city}
          items={cities}
        />
        <SelectFilter
          label={"common.status"}
          placeholder={"superAdmin.table.users.filters.statusSelect"}
          defaultValue={"superAdmin.table.users.filters.allStatuses"}
          onChange={(status) =>
            setFilters((prevState) => ({ ...prevState, status }))
          }
          value={filters?.status}
          items={userStatuses}
        />
      </InputFieldContainer>,
    ],
    [filters]
  );

  const AddFilters = () => {
    console.log("Add filters clicked");
  };

  const [selectedUser, setSelectedUser] = useState(null);

  const userDetailsBtnClick = (user) => {
    setSelectedUser(user);
  };

  return (
    <MainLayout>
      <PageHeader
        leftIcon={<UsersIconSelected />}
        pageTitle={t("superAdmin.table.tableHeaderForUsers")}
        onClick={AddFilters}
        isAddButtonShown={false}
        isFilterButtonShown={!selectedUser}
        filterComponents={filterComponents}
        saveFilters={saveFilters}
      />
      {selectedUser == null ? (
        <Table
          mapRowDataFunction={mapRowDataFunction}
          tableHeaders={usersTableConstants}
          usePaginatedQuery={useGetPaginatedFilteredUsersQuery}
          rowBtnClick={userDetailsBtnClick}
          appliedFilters={appliedFilters}
          t={t}
        />
      ) : (
        <SelectedUserContainer
          userId={selectedUser?.id}
          onClickBackBtn={() => setSelectedUser(null)}
        />
      )}
    </MainLayout>
  );
};

export default UsersPage;
