import React from "react";
import DashboardContent from "components/Dashboard/DashboardContent";
import MainLayout from "layouts/MainLayout";
import { useTranslation } from "react-i18next";
import PageHeader from "components/PageHeader/PageHeader";
import { ControlTableIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";

const DashboardPage = () => {
  const { t } = useTranslation();
  return (
    <MainLayout>
      <PageHeader
        leftIcon={<ControlTableIconSelected />}
        pageTitle={t("superAdmin.dashboardTitle")}
      />
      <DashboardContent />
    </MainLayout>
  );
};

export default DashboardPage;
