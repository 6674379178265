import React from "react";
import PropTypes from "prop-types";
import {
  ArrwoDownStyled,
  FullNameTypography,
  HeaderProfileContainer,
  RoleTypography,
  StyledPopover,
  TypographyContainer,
} from "./HeaderProfile.styled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { HeaderIconContainer } from "components/Header/Header.styled";
import HeaderProfilePopoverContent from "./HeaderProfilePopoverContent/HeaderProfilePopoverContent";
import { selectCurrentUser } from "features/auth/authSlice";
import { useSelector } from "react-redux";

const HeaderProfile = (props) => {
  const user = useSelector(selectCurrentUser);

  return (
    <HeaderProfileContainer>
      <HeaderIconContainer>
        <AccountCircleIcon />
      </HeaderIconContainer>
      {props.isLoggedIn && (
        <TypographyContainer>
          <FullNameTypography>
            {user.firstName + " " + user.lastName}
          </FullNameTypography>
          <RoleTypography>
            {Array.isArray(user.role) ? user.role[0] : user.role}
          </RoleTypography>
        </TypographyContainer>
      )}
      <StyledPopover
        trigger={<ArrwoDownStyled />}
        content={<HeaderProfilePopoverContent isLoggedIn={props.isLoggedIn} />}
      />
    </HeaderProfileContainer>
  );
};

HeaderProfile.propTypes = {
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool,
};

export default HeaderProfile;
