import React from "react";
import PropTypes from "prop-types";
import ValidationMessage from "components/ValidationMessage/ValidationMessage";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";

const SentCodeModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onBtnClick = () => {
    dispatch(unsetShownModal());
  };
  return (
    <ValidationMessage
      title={t(`validationmessage.sentCodeTitle`)}
      type={props.authType}
      onClose={onBtnClick}
    />
  );
};

SentCodeModal.propTypes = {
  authType: PropTypes.string,
};

export default SentCodeModal;
