import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AddButtonContainer,
  FilterContainer,
  HeaderContainer,
  LabelContainer,
  MainContainer,
  StyledTypography,
  UpperContainterWithProfilePicture,
  FilterButtonContainer,
  UpperFilterContainer,
  InputFieldsContainer,
  LowerFilterContainer,
  CancelButton,
  FilterTitle,
  X_IconStyled,
  ActionsContainer,
} from "./PageHeader.styled";
import AddNewItemButton from "../SuperAdmin/Buttons/AddNewButton/AddNewItemButton";
import { TEXTVARIANT } from "constants/textConstants";
import FilterButton from "../SuperAdmin/Buttons/FilterButton/FilterButton";
import { FilterPopoverContainer, SaveFiltersButton } from "./PageHeader.styled";
import { useTranslation } from "react-i18next";
import HeaderProfileBar from "components/Header/HeaderProfileBar/HeaderProfileBar";
import { useAuth } from "hooks/useAuth";

export const PageHeader = (props) => {
  const { t } = useTranslation();
  const [isFiltersShown, setIsFiltersShown] = useState(false);
  const { isLoggedIn } = useAuth();
  const saveFilters = () => {
    setIsFiltersShown(false);
    props?.saveFilters?.();
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <UpperContainterWithProfilePicture>
          <LabelContainer>
            {props?.leftIcon}
            <StyledTypography variant={TEXTVARIANT.H4}>
              {props.pageTitle}
            </StyledTypography>
          </LabelContainer>
          <HeaderProfileBar isLoggedIn={isLoggedIn} />
        </UpperContainterWithProfilePicture>
        {props.isAddButtonShown && (
          <AddButtonContainer>
            <AddNewItemButton
              value={props.buttonText}
              onClick={props.onClick}
            />
          </AddButtonContainer>
        )}
        <FilterContainer>
          {props?.isFilterButtonShown && (
            <div>
              <FilterButtonContainer>
                <FilterButton
                  onClick={() => setIsFiltersShown((prevState) => !prevState)}
                />
              </FilterButtonContainer>
              {isFiltersShown && (
                <FilterPopoverContainer>
                  <UpperFilterContainer>
                    <FilterTitle>{t("common.filter")}</FilterTitle>
                    <X_IconStyled onClick={() => setIsFiltersShown(false)} />
                  </UpperFilterContainer>
                  <LowerFilterContainer>
                    <InputFieldsContainer>
                      {props?.filterComponents?.map?.(
                        (filterComponent) => filterComponent
                      )}
                    </InputFieldsContainer>
                    <ActionsContainer>
                      <CancelButton
                        value={t("common.cancel")}
                        onClick={() => setIsFiltersShown(false)}
                      />
                      <SaveFiltersButton
                        value={t("common.confirm")}
                        onClick={saveFilters}
                      />
                    </ActionsContainer>
                  </LowerFilterContainer>
                </FilterPopoverContainer>
              )}
            </div>
          )}
        </FilterContainer>
      </HeaderContainer>
      <FilterContainer />
    </MainContainer>
  );
};

PageHeader.propTypes = {
  leftIcon: PropTypes.node,
  pageTitle: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  isAddButtonShown: PropTypes.bool,
  isFilterButtonShown: PropTypes.bool,
  filterComponents: PropTypes.array,
  saveFilters: PropTypes.func,
};

export default PageHeader;
