import React from "react";
import UpdateRenderer from "components/Table/UpdateRenderer";

export default [
  {
    columnId: 0,
    headerName: "superAdmin.table.tableHeaderForServiceCategories",
    field: "name",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 1,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <UpdateRenderer params={params} onClick={params.onClick} />
      //<UpdateRenderer params={params} value={"superAdmin.table.editButton"} />
    ),
  },
];
