import styled from "styled-components";
import { Box } from "@mui/material";
import { ReactComponent as LogoImage } from "../../assets/images/svg/sidebar-logo.svg";
import { ReactComponent as MiniLogoImage } from "../../assets/images/svg/sidebar-collapsed-logo.svg";
import { ReactComponent as LeftArrowImg } from "../../assets/images/svg/double-left-arrow.svg";
import { ReactComponent as RightArrowImg } from "../../assets/images/svg/double-right-arrow.svg";
import { hexToRGB } from "util/colorHelper";
import { Link } from "react-router-dom";

export const LogoContainer = styled(LogoImage)`
  width: 150px;
  height: auto;
  padding-bottom: 20px;
  cursor: pointer;
`;

export const CollapsedLogoContainer = styled(MiniLogoImage)`
  width: 90px;
  height: 40px;
  margin: auto;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  margin-bottom: 0;
  display: flex;
`;

export const SidebarContainer = styled(Box)`
  position: fixed;
  width: ${({ collapsed }) => (collapsed ? "95px" : "300px")};
  transition: width 0.3s ease-in-out;
  margin: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props?.theme?.colors?.darkBlue};
  gap: 64px;
  border-radius: 24px;
  box-shadow: 5px 5px 10px
    ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  padding: ${({ collapsed }) =>
    collapsed ? "32px 0px 32px 0px" : "32px 0px 32px 25px"};
  height: calc(100% - 40px);
`;

export const TabsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: flex-end;
`;

export const IndividualTab = styled(Box)`
  display: flex;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme?.colors?.white : "transparent"};
  color: ${({ selected, theme }) =>
    selected ? theme?.colors?.white : theme?.colors?.black};
  &:hover {
    background-color: ${(selected, theme) =>
      selected ? theme?.colors?.white : theme?.colors?.hoverDarkBlue};
  }
  width: 100%;
`;

export const ArrowImage = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: right;
  padding-right: ${({ showArrow }) => (showArrow ? "16px" : "0px")};
  margin-right: 16px;
  align-items: flex-end;
`;

export const LeftArrowImage = styled(LeftArrowImg)`
  display: ${({ hideArrow }) => (hideArrow ? "none" : "block")};
  cursor: pointer;
`;

export const RightArrowImage = styled(RightArrowImg)`
  visibility: ${({ showArrow }) => (showArrow ? "visible" : "hidden")};
  cursor: pointer;
`;
