import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const serviceCategoriesApi = apiSlice.injectEndpoints({
  tagTypes: ["Table"],
  endpoints: (builder) => ({
    getAllServiceCategories: builder.query({
      query: () => ({
        url: apiEndpoints.serviceCategories.getAll,
      }),
      providesTags: ["Table"],
    }),
    getPaginatedServiceCategories: builder.query({
      query: ({ pageNumber = 1, pageSize = 10 }) => ({
        url: apiEndpoints.serviceCategories.getPaginated
          .replace("{pageNumber}", pageNumber)
          .replace("{pageSize}", pageSize),
      }),
      providesTags: ["Table"],
    }),
    addServiceCategory: builder.mutation({
      query: (credentials) => ({
        url: apiEndpoints.serviceCategories.create,
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Table"],
    }),
    updateServiceCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: apiEndpoints.serviceCategories.update.replace("{id}", id),
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Table"],
    }),
  }),
});

export const {
  useGetAllServiceCategoriesQuery,
  useGetPaginatedServiceCategoriesQuery,
  useAddServiceCategoryMutation,
  useUpdateServiceCategoryMutation,
} = serviceCategoriesApi;
