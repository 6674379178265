import styled, { css } from "styled-components";
import { Typography } from "@mui/material";

const CustomFieldLabelStyled = styled(Typography)`
  padding-left: 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: black;
  ${(props) =>
    props?.required &&
    css`
      &::after {
        content: " *";
      }
    `}
`;

export default CustomFieldLabelStyled;
