import CityField from "components/RegistrationContent/InputFields/CityField";
import styled from "styled-components";
import { ReactComponent as UserImage } from "assets/images/svg/mini-logo-tremium.svg";
import { Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";
import LongDashStyled from "components/LongDash/LongDash.styled";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 23px;
  gap: 20px;
  box-shadow: 2px 2px 8px
    ${(props) => hexToRGB(props?.theme?.colors?.boxShadow, 0.3)};
  width: 327px;
`;

export const StatusContainer = styled.div`
  display: flex;
  gap: 10px;
  align-self: flex-end;
`;

export const StatusText = styled(Typography)`
  align-self: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: ${(props) =>
    props.active
      ? props?.theme?.colors?.statusActiveButton
      : props?.theme?.colors?.statusInactiveButton};
  transition: 0.3s ease;
`;

export const StatusButtonContainer = styled.div`
  width: 36px;
  height: 20px;
  padding: 2px;
  background-color: ${(props) =>
    props.active
      ? props?.theme?.colors?.statusActiveButton
      : props?.theme?.colors?.statusInactiveButton};
  display: flex;
  justify-content: ${(props) => (props.active ? "flex-end" : "flex-start")};
  border-radius: 12px;
  cursor: pointer;
  color: ${(props) => props?.theme?.colors?.white};
  font-size: 18px;
  transition: 0.3s ease;
`;

export const LongDash = styled(LongDashStyled)`
  background-color: ${(props) => hexToRGB(props?.theme?.colors?.black, 0.1)};
  height: 2px;
`;

export const ImageContainer = styled(UserImage)`
  width: 120px;
  height: 120px;
  border-radius: 90px;
  align-self: center;
`;

export const NameContainer = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
`;

export const ActiveCircle = styled.div`
  width: 50%;
  height: 100%;
  border-radius: 90px;
  background-color: ${(props) => props?.theme?.colors?.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionContainer = styled.div`
  width: fit-content;
`;

export const CityFieldStyled = styled(CityField)``;

export const DataContainer = styled.div``;
