import { Select, TextField } from "@mui/material";
import styled from "styled-components";

export const PhoneFieldContainer = styled.div`
  display: flex;
`;

export const CountryPhoneCodeSelect = styled(Select)`
  width: 33%;
  & .MuiOutlinedInput-input {
    padding-right: 15px !important;
    padding-left: 20px;
    background-color: ${(props) =>
      props?.theme?.colors?.selectCountryPhoneCodeBackground};
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props?.theme?.colors?.fieldBorderColor};
    border-right: none;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border: ${(props) => {
      if (!props.disabled) return "1px solid";
    }};
    border-right: ${(props) => {
      if (props.disabled) return "none";
    }};
    border-color: ${(props) => {
      if (!props.disabled) return props?.theme?.colors?.secondaryColor;
    }};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid;
    border-color: ${(props) =>
      props.disabled
        ? `1px solid ${(props) => props?.theme?.colors?.fieldBorderColor}`
        : props?.theme?.colors?.secondaryColor};
  }
`;

export const PhoneNumberContainer = styled(TextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid ${(props) => props?.theme?.colors?.fieldBorderColor};
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    &:hover fieldset {
      border-color: ${(props) => props?.theme?.colors?.secondaryColor};
    }
    &.Mui-focused fieldset {
      border-color: ${(props) => props?.theme?.colors?.secondaryColor};
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  & .MuiFormHelperText-root {
    font-size: 14px;
    margin-left: 24px;
    font-weight: 400;
    position: absolute;
    top: 100%;
  }
`;
