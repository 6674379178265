import React from "react";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <MainLayout>
      <PageHeader pageTitle={t("pages.about")} />
    </MainLayout>
  );
};

export default AboutPage;
