import React from "react";
import { useSelector } from "react-redux";
import { selectModal } from "features/modal/modalSlice";
import ServiceCategoryEditAddModal from "components/SuperAdmin/ServiceCategoryEditAddModal/ServiceCategoryEditAddModal";
import SentCodeModal from "./SentCodeModal/SentCodeModal";
import FinishedRegistrationModal from "components/RegistrationContent/FinishedRegistrationModal/FinishedRegistrationModal";
import ChangeUserStatusModal from "components/SuperAdmin/ChangeUserStatusModal/ChangeUserStatusModal";

const Modal = () => {
  const modal = useSelector(selectModal);

  return (
    <>
      {modal.serviceCategoryModal && (
        <ServiceCategoryEditAddModal {...modal.serviceCategoryModalProps} />
      )}

      {modal.finishedRegistrationModal && (
        <FinishedRegistrationModal {...modal.finishedRegistrationModalProps} />
      )}
      {modal.sentCodeModal && <SentCodeModal {...modal.sentCodeModalProps} />}
      {modal.changeUserStatusModal && (
        <ChangeUserStatusModal {...modal.sentCodeModalProps} />
      )}
    </>
  );
};

export default Modal;
