import React from "react";
import PropTypes from "prop-types";
import CustomFieldLabelStyled from "./CustomFieldLabel.styled";

const CustomFieldLabel = (props) => {
  return (
    <CustomFieldLabelStyled required={props?.required}>
      {props.label}
    </CustomFieldLabelStyled>
  );
};

CustomFieldLabel.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

CustomFieldLabel.defaultProps = {
  required: false,
};

export default CustomFieldLabel;
