import React from "react";
import PropTypes from "prop-types";
import {
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  StyledTypography,
  LastCell,
} from "./TableBodyContainer.styled";
import { TEXTVARIANT } from "constants/textConstants";

const TableBodyContainer = (props) => {
  return (
    <StyledTableBody>
      {props.rows.map((row) => (
        <StyledTableRow key={row.id}>
          {props.tableHeaders.map((col, index) => {
            let data = row?.[col?.field];
            const formattedData = col?.valueFormatter?.(data) ?? data;
            const isLastColumn = index === props.tableHeaders.length - 1;
            const cellRendered = col?.cellRenderer ? (
              col.cellRenderer({
                name: formattedData,
                onClick: () => props.rowBtnClick(row),
              })
            ) : (
              <StyledTypography variant={TEXTVARIANT.H5}>
                {formattedData}
              </StyledTypography>
            );
            return isLastColumn ? (
              <LastCell key={col.field}>{cellRendered}</LastCell>
            ) : (
              <StyledTableCell key={col.field}>{cellRendered}</StyledTableCell>
            );
          })}
        </StyledTableRow>
      ))}
    </StyledTableBody>
  );
};

TableBodyContainer.propTypes = {
  rows: PropTypes.any,
  rowBtnClick: PropTypes.func,
  tableHeaders: PropTypes.any,
  theme: PropTypes.any,
};

export default TableBodyContainer;
