export default {
  app: {
    title: "Service Appointments",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  logo: {
    text: "Service Appointemnts",
  },
  common: {
    finish: "Završi",
    serviceCategories: "serviceCategories",
    from: "od",
    language: "Jezik",
    english: "Engleski",
    serbian: "Srpski",
    dataGridExample: "Primer Data Grid-a",
    close: "Zatvori",
    trademark: "TM",
    search: "Pretraga",
    error: "Greška",
    continue: "Nastavite",
    labelLocation: "Lokacija",
    labelUsername: "Korisničko ime",
    labelEmail: "Email",
    labelPassword: "Šifra",
    labelCompanyName: "Naziv firme",
    labelPrimaryService: "Primarna delatnost",
    labelCountry: "Država",
    labelCity: "Grad",
    labelAddress: "Adresa",
    labelPhone: "Telefon",
    labelPhoneNumber: "Broj telefona",
    labelFirstName: "Ime",
    labelLastName: "Prezime",
    labelPasswordConfirmation: "Potvrda šifre",
    labelClientName: "Ime klijenta",
    clients: "Klijenti",
    status: "Status",
    usernameInput: "Unesite korisničko ime",
    passwordInput: "Unesite lozinku",
    email: "Unesite svoj email",
    companyNameInput: "Unesite naziv firme",
    primaryServiceSelect: "Izaberite primarnu delatnost",
    countrySelect: "Izaberite državu",
    citySelect: "Izaberite grad",
    addressInput: "Unesite adresu",
    phoneInput: "Unesite telefon",
    firstNameInput: "Unesite ime",
    lastNameInput: "Unesite prezime",
    passwordConfirmationInput: "Potvrdite šifru",
    codeInput: "Unesite kod",
    next: "Dalje",
    nextTablePage: "Sledeća >",
    nextPage: "Sledeća stranica",
    previousTablePage: "< Prethodna",
    previousPage: "Predhodna stranica",
    back: "Nazad",
    goBack: "Idite nazad",
    ok: "U redu",
    done: "Gotovo",
    confirm: "Potvrdite",
    printDownload: "Print/Download",
    cancel: "Otkaži",
    remove: "Remove",
    invite: "Invite",
    add: "Dodaj",
    save: "Sačuvaj",
    edit: "Izmeni",
    details: "Detalji",
    delete: "Obriši",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    or: "ili",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} do {{end}}",
    },
    logout: "Izloguj se",
    seeMore: "Vidi još",
    users: "Korisnici",
    filter: "Filter",
  },
  code: {
    sentEmailMessage:
      "Poslali smo Vam kod za potvrdu na email adresu koju ste naveli. Molimo Vas da proverite svoju email adresu i unesete kod kako biste završili proces registracije.",
    labelSentEmailCodeInput: "Unesite kod koji Vam je stigao na mail",
    sentPhoneMessage:
      "Poslali smo Vam kod za potvrdu na broj telefona kojiste naveli. Molimo vas da proverite telefon i unesete kod kako biste završili proces registracije.",
    labelSentPhoneCodeInput: "Unesite kod koji Vam je stigao u SMS poruci",
    sendNewCode: "Zatražite novi kod",
  },
  notifications: {
    title: "Obaveštenja",
  },
  pages: {
    home: "Početna",
    login: "Login",
    users: "Korisnici",
    clients: "Klijenti",
    register: "Register",
    forgotPassword: "Zaboravljena lozinka",
    resetPassword: "Reset passworda",
    error: "Pogrešna stranica",
    notFound: "Nije pronađena stranica",
    profile: "Profilna stranica",
    settings: "Podešavanja",
    dashboard: "Komandna tabla",
    services: "Usluge",
    controlTable: "Kontrolna tabla",
    serviceCategories: "Delatnosti",
    about: "O nama",
    help: "Pomoć",
    contact: "Kontaktirajte nas",
    messages: "Poruke",
    appointments: "Termini",
  },
  register: {
    registerTitle: "Registrujte se",
    alreadyHaveAccount: "Već imate nalog?",
    usernameRequired: "Korisničko ime je obavezno.",
    emailFormat: "Format email-a nije validan.",
    passwordLength: "Šifra mora da sadrži između 8 i 50 karaktera.",
    emailRequired: "An email or username is required.",
    passwordsDoNotMatch: "Šifre se ne poklapaju.",
    passwordUppercase: "Šifra mora da sadrži veliko slovo.",
    passwordLowercase: "Šifra mora da sadrži malo slovo.",
    passwordNumber: "Šifra mora da sadrži broj.",
    passwordSpecial: "Šifra mora da sadrži specijalni karakter.",
    passwordRequired: "A Password is required.",
    passwordConfirm: "Potvrdite šifru",
    inputRequired: "Polje je obavezno.",
    usernameAlreadyTaken: "Koriscničko ime se već koristi.",
    emailAlreadyTaken: "Email se već koristi.",
    progress: {
      title: {
        client: "Registrujte se kao pružalac usluga u par koraka",
        user: "Registrujte se kao korisnik usluga u par koraka",
      },
      client: {
        steps: [
          "Informacije o pružaocu usluga",
          "Lični podaci:",
          "Potvrdite Vaš e-mail",
        ],
      },
      user: {
        steps: ["Lični podaci:", "Dodatne informacije", "Potvrdite Vaš e-mail"],
      },
    },
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Nemate nalog? ",
    emailFormat: "Loš format email-a",
    emailRequired: "Email/korisničko ime je obavezno",
    noUsers: "Ne postoji korisnik",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    signUpRecommendation: "Registrujte se",
    email: "Unesite svoj email",
    password: "Unesite šifru",
    logInTitle: "Ulogujte se",
    logIn: "Ulogujte se",
    continueWithGoogle: "Nastavite preko google-a",
    signUp: "Sign Up",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Zaboravili ste šifru?",
    forgotPasswordEmailSent: "Ako imate nalog uskoro će vam stići email.",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Zaboravili ste šifru",
    label: "Pošaljite email",
    emailRequired: "Email je obavezan.",
    emailFormat: "Nevalidan format za email.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  resetPassword: {
    label: "Resetuj šifru",
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
  date: {
    timespan: {
      yearsAgo: "Pre {{years}} godine",
      monthsAgo: "Pre {{months}} meseca",
      daysAgo: "Pre {{days}} dana",
      hoursAgo: "Pre {{hours}} sata",
      minutesAgo: "Pre {{minutes}} minuta",
      secondsAgo: "Pre {{seconds}} sekunde",
      now: "Upravo sada",
    },
  },
  dashboard: {
    charts: {
      lineChart: "Linijski grafikon",
      pieChart: "Kružni grafikon",
      barChart: "Dijagram",
      radarChart: "Radarski grafikon",
    },
  },
  middlepage: {
    alreadyGotAccount: "Već imate nalog? ",
    user: {
      title: "Registruj se kao <0>korisnik</0> usluga",
      text: "Želiš da na brz i jednostavan način zakažeš termin za uslugu koja ti je potrebna",
      button: "Registruj se kao korisnik",
    },
    client: {
      title: "Registruj se kao <0>pružalac</0> usluga",
      text: "Baviš se pružanjem usluga i potreban ti je lak način da zakazuješ termine",
      button: "Registruj se kao klijent",
    },
  },
  validationmessage: {
    sentCodeTitle: "Poslat Vam je novi kod!",
    client: {
      title: "Uspešno ste se registrovali!",
      text: "Spremni ste za prve klijente. Pratite zakazivanja i upravljajte terminima na jednostavan način.",
    },
    user: {
      title: "Vaša registracija je završena!",
      text: "Sada možete jednostavno zakazivati termine za željene usluge.",
    },
    button: "Zatvori",
  },
  superAdmin: {
    pageTitle: "Super admin",
    dashboardTitle: "Kontrolna tabla",
    table: {
      numberOfShowingResultsBeforeSelect: "Prikaži",
      numberOfShowingResultsAfterSelect: "od {{total}} podataka",
      tableHeaderForClients: "Klijenti",
      tableHeaderForUsers: "Korisnici",
      tableHeaderForServiceCategories: "Delatnosti",
      tableHeaderForLicenses: "Licence",
      editButton: "Izmeni",
      serviceCategories: {
        addButtonForServiceCategories: "Dodaj novu delatnost",
        editButtonForServiceCategories: "Izmeni delatnost",
        descriptionForServiceCategories: "Opis",
        successfullyAdded: "Uspešno dodata delatnost",
        successfullyUpdated: "Uspešno ažurirana delatnost",
      },
      clients: {
        addButtonForClients: "Dodaj novog klijenta",
        locationForClients: "Lokacija",
        statusForClients: "Status",
        statusActive: "Aktivan",
        statusInactive: "Neaktivan",
        filters: {
          clientNameInput: "Unesi ime klijenta",
          citySelect: "Izaberi grad",
          allCities: "Svi gradovi",
          statusSelect: "Izaberi status",
          allStatuses: "Svi statusi",
          primaryServiceSelect: "Izaberi primarnu delatnost",
          allServices: "Sve delatnosti",
        },
      },
      users: {
        addButtonForUsers: "Dodaj novog korisnika",
        filters: {
          userNameInput: "Unesi ime korisnika",
          emailInput: "Unesi email",
          citySelect: "Izaberi grad",
          allCities: "Svi gradovi",
          statusSelect: "Izaberi status",
          allStatuses: "Svi statusi",
        },
        labelStatusActive: "Aktivan:",
        labelStatusInactive: "Neaktivan:",
        buttonActivate: "Aktiviraj",
        buttonDeactivate: "Deaktiviraj",
        confirmationStatusChangeMessage:
          "Da li ste sigurni da želite da {{action}} korisnika?",
        toActivate: "aktivirate",
        toDeactivate: "deaktivirate",
        successfullyChangedStatus: "Uspešno promenjen status korisnika",
        unsuccessfullyChangedStatus: "Neuspešno promenjen status korisnika",
      },
      licenses: {
        addButonForLicenses: "Dodaj novu licencu",
        updated: "Datum ažuriranja",
        issuedAtUtc: "Važi od",
        expiresAtUtc: "Važi do",
        endDate: "Važi do",
        startDate: "Važi od",
        updatedAtUtc: "Ažurirana",
        type: "Tip licence",
        selectLicenseType: "Izaberi tip licence",
        chooseLicence: "Unesi licencu",
        licenseType: {
          trial: "Trial",
          full: "Full",
        },
        addNewLicense: "Dodaj novu licencu",
        editLicense: "Izmeni licencu",
        successfullyUpdated: "Licenca je uspešno ažurirana",
        successfullyAdded: "Licenca je uspešno dodata",
        status: "Status",
        statusActive: "Aktivan",
        statusInactive: "Neaktivan",
        startDateRequired: "Potreban je datum početka važenja licence",
        endDateRequired: "Potreban je datum isetka licence",
        licenseTypeRequired: "Tip licence je obavezan",
        startAndEndNotGood:
          "Datum isteka licence ne može biti pre datuma početka",
      },
    },
  },
  profile: {
    image: "Slika profila",
    chooseImage: "Izaberi sliku za profil",
    form: {
      step1: "Lični podaci",
      step2: "Adresa",
      step3: "Posao",
    },
    labels: {
      firstName: "Ime",
      lastName: "Prezime",
      gender: "Pol",
      city: "Grad",
      street: "Ulica",
      postalCode: "Poštanski broj",
      companyName: "Ime firme",
      companyAddress: "Adresa firme",
      yearsInCompany: "Broj godina u firmi",
    },
  },
  serviceCategory: {
    addNewServiceCategory: "Dodaj novu delatnost",
    editServiceCategory: "Izmeni delatnost",
    inputField: {
      label: {
        name: "Ime",
        description: "Opis",
      },
      placeholder: {
        name: "Unesite ime",
        description: "Unesite opis",
      },
    },
  },

  footer: {
    left: "Termium <0>Copyright</0> © <0>2024</0>",
    companyName: "Tremium Software.",
    rights: "Sva prava zadržana.",
  },

  sidebar: {
    controlTable: "Kontrolna tabla",
    services: "Delatnosti",
    clients: "Klijenti",
    users: "Korisnici",
  },
};
