import styled from "styled-components";
import { ReactComponent as CheckImg } from "../../assets/images/svg/check-solid.svg";
import { ReactComponent as LogInImg } from "../../assets/images/svg/log-in-img.svg";
import AuthType from "constants/authType";

export const LogImg = styled(LogInImg)`
  width: 460px;
  height: 711px;
`;

export const CheckIcon = styled(CheckImg)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.white};
    fill: ${(props) => props?.theme?.colors?.white};
  }
  width: 24px;
  height: 24px;
  display: inline-block;
`;

export const ProgressContainer = styled.div`
  background-color: ${(props) =>
    props?.theme?.colors?.leftAuthContentBackground};
  padding: 45px;
  gap: 10px;
  border-radius: 15px;
  width: 460px;
  height: 711px;
  border-radius: 16px;
`;

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: left;
  max-width: 315px;
  margin-bottom: 75px;
  overflow-wrap: break-word;
  word-break: break-word;
  color: ${(props) => props?.theme?.colors?.textWhite};
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const Circle = styled.div`
  width: 46.32px;
  height: 46.32px;
  min-width: 46.32px;
  min-height: 46.32px;
  border-radius: 50%;
  background-color: ${({ completed, type, theme }) =>
    completed
      ? type === AuthType.CLIENT
        ? theme.colors.clientRegistration.Circle
        : theme.colors.userRegistration.Circle
      : theme.colors.whiteOpacity30};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: ${({ completed, theme }) =>
    completed
      ? theme.colors.leftAuthContentBackground
      : theme.colors.textUncompletedStep};
  position: relative;

  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Line = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  height: 64px;
  width: 0px;
  border-left: 4px solid
    ${({ completed, type, theme }) =>
      completed
        ? type === AuthType.CLIENT
          ? theme.colors.clientRegistration.Circle
          : theme.colors.userRegistration.Circle
        : theme.colors.uncompletedRegistrationBackground};
  z-index: 1;
`;

export const Label = styled.span`
  margin-left: 10px;
  color: ${({ completed, theme }) =>
    completed ? theme.colors.textWhite : theme.colors.textUncompletedStep};
  font-size: 24px;
  font-weight: 600;
  line-height: 32.74px;
  text-align: left;
`;
