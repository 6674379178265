import React from "react";
import MainLayout from "layouts/MainLayout";
import { useTranslation } from "react-i18next";
import PageHeader from "components/PageHeader/PageHeader";

const HelpPage = () => {
  const { t } = useTranslation();
  return (
    <MainLayout>
      <PageHeader pageTitle={t("pages.help")} />
    </MainLayout>
  );
};

export default HelpPage;
