import React from "react";
import UpdateRenderer from "components/Table/UpdateRenderer";
import { formatDateTimeLocal } from "util/dateHelpers";

const licenseTypeMap = {
  0: "Trial",
  1: "Full"
};

export default [
  {
    columnId: 0,
    headerName: "superAdmin.table.licenses.type",
    field: "type",
    enabledSort: false,
    enabledFilter: false,
    valueFormatter: (value) => licenseTypeMap[value] || "Unknown",
  },
  {
    columnId: 1,
    headerName: "superAdmin.table.licenses.issuedAtUtc",
    field: "startDateUtc",
    enabledSort: false,
    enabledFilter: false,
    valueFormatter: (value) => formatDateTimeLocal(value),
  },
  {
    columnId: 2,
    headerName: "superAdmin.table.licenses.expiresAtUtc",
    field: "endDateUtc",
    enabledSort: false,
    enabledFilter: false,
    valueFormatter: (value) => formatDateTimeLocal(value),
  },
  {
    columnId: 3,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <UpdateRenderer params={params} onClick={params.onClick} value={"superAdmin.table.editButton"} />
    ),
  },
];