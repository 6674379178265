import styled from "styled-components";
import { ReactComponent as ClientImg } from "assets/images/svg/client-validation-message.svg";
import { ReactComponent as UserImg } from "assets/images/svg/user-validation-message.svg";
import { hexToRGB } from "util/colorHelper";
import Button from "components/Button/Button";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  z-index: 10000;
`;

export const Modal = styled.div`
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  position: relative;
  background-color: white;
  border-radius: 24px;
  width: 460px;
  height: fit-content;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Divider = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 2px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  height: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 250px;
  gap: 20px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32.74px;
  width: 80%;
  align-self: center;
`;

export const CloseButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
  color: ${({ theme }) => theme.colors.textBlack};
  padding: 6px 24px 6px 24px;
  border-radius: 12px;
  border-color: ${({ theme }) => hexToRGB(theme?.colors?.black, 0.3)};
  cursor: pointer;
  height: fit-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 50%;
  align-self: center;
`;

export const ActionButton = styled(CloseButton)`
  background-color: ${({ theme, active }) =>
    active
      ? theme.colors.statusInactiveButton
      : theme.colors.statusActiveButton};
  &:hover {
    background-color: ${({ theme, active }) =>
      active
        ? theme.colors.statusInactiveButton
        : theme.colors.statusActiveButton};
  }
  color: ${({ theme }) => theme.colors.white};
`;

const StyledImage = styled.img`
  width: 170px;
  height: 121px;
  display: block;
  margin: 0 auto;
`;

export const StyledClientImg = styled(StyledImage).attrs({ as: ClientImg })``;
export const StyledUserImg = styled(StyledImage).attrs({ as: UserImg })`
  padding-top: 20px;
`;
