import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import { useResetPasswordMutation } from "features/forgotPassword/forgotPasswordSlice";
import PasswordField from "components/InputFields/PasswordField";
import { FormikParams } from "constants/formikParams";
import resetPasswordValidation from "validations/resetPasswordValidation";
import { useNavigate, useLocation } from "react-router-dom";
import { PAGES } from "constants/pages";
import { VARIANT } from "constants/buttonConstants";

const ResetPasswordPage = () => {
  const [passwordsDoNotMatchError, setPasswordsDoNotMatchError] =
    useState(null);
  const { t } = useTranslation();
  const [useResetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();

  const location = useLocation();

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);
  const id = queryParams.get("id");
  const token = queryParams.get("token");

  const handleSubmit = async () => {
    const data = {
      password: formik.values.password,
      confirmedPassword: formik.values.confirmedPassword,
    };
    if (!id || !token) {
      return;
    }
    const response = await useResetPassword({
      id: id,
      token: token,
      body: data,
    });
    if (response.error) {
      response.error.data.errors.map((error) => {
        switch (error.code) {
          default:
            alert(error.message);
            break;
        }
      });
    } else {
      alert("Password successfully changed!");
      navigate(PAGES.LOGIN.route);
    }
  };

  const formik = useFormik({
    initialValues: { password: "", confirmedPassword: "" },
    validationSchema: resetPasswordValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const clearPasswordConfirmErrorMessage = () => {
    if (passwordsDoNotMatchError != null) {
      setPasswordsDoNotMatchError(null);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "25px" }}>
      <PasswordField formik={formik} required />
      <PasswordField
        label="common.labelPasswordConfirmation"
        name={FormikParams.confirmedPassword}
        placeholder="common.passwordConfirmationInput"
        formik={formik}
        required
        helperText={passwordsDoNotMatchError}
        clearErrorMessage={clearPasswordConfirmErrorMessage}
      />
      <Button variant={VARIANT.OUTLINED} type="submit" onClick={handleSubmit}>
        {t("resetPassword.label")}
      </Button>
    </Box>
  );
};

export default ResetPasswordPage;
