import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomTextField from "components/InputFields/CustomFields/CustomTextField";
import PropTypes from "prop-types";

const StartDateField = (props) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    props?.formik?.handleChange(event);
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)}></CustomFieldLabel>
      <CustomTextField
        type="date"
        name={props.name}
        formik={props.formik}
        onChange={handleChange}
        helperText={props.formik.touched[props.name] && props.formik.errors[props.name]}
        error={props.formik.touched[props.name] && !!props.formik.errors[props.name]}
      ></CustomTextField>
    </Box>
  );
};

StartDateField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  formik: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
};

StartDateField.defaultProps = {
  props: {
    label: "superAdmin.table.licenses.startDate",
    name: "startDate",
  },
};

export default StartDateField;
