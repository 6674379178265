import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  InputFieldContainer,
  InputFieldsContainer,
  SentCodeBtnContainer,
  SentCodeMessageContainer,
  UpperContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import SentCodeField from "components/RegistrationContent/InputFields/SentCodeField";
import { useTranslation } from "react-i18next";
import SendNewCodeButton from "components/RightAuthContent/Buttons/SendNewCodeButton/SendNewCodeButton";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import { useFormik } from "formik";
import { UserStepThreeAtributes } from "initialValues/userRegInitialValues.js";
import { UserStepThreeValidation } from "validations/registerUserValidation";
import AuthType from "constants/authType";
import {
  useVerifyEmailMutation,
  useRequestEmailVerificationCodeMutation,
} from "features/register/userRegisterApiSlice";
import { userColors } from "themes/secondaryTheme/secondaryThemeColors";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserAttributesStepOne,
  selectUserAttributesStepTwo,
} from "features/register/userRegisterSlice";
import {
  setFinishedRegistrationModal,
  setSentCodeModal,
} from "features/modal/modalSlice";

const UserStepThree = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [verifyEmail] = useVerifyEmailMutation();
  const [requestEmailVerificationCode] =
    useRequestEmailVerificationCodeMutation();
  const [verificationCodeError, setVerificationCodeError] = useState(null);

  const clearVerificationCodeErrorMessage = () => {
    if (verificationCodeError != null) {
      setVerificationCodeError(null);
    }
  };

  const userDataStepOne = useSelector(selectUserAttributesStepOne);
  const userDataStepTwo = useSelector(selectUserAttributesStepTwo);

  const dataForRegistration = {
    firstName: userDataStepOne?.firstName,
    lastName: userDataStepOne?.lastName,
    email: userDataStepOne?.email,
    password: userDataStepOne?.password,
    country: userDataStepTwo?.country.id,
    city: userDataStepTwo?.city.id,
    address: userDataStepTwo?.address,
  };

  const handleSubmit = async () => {
    console.log(dataForRegistration);
    const response = await verifyEmail({
      ...dataForRegistration,
      verificationCode: formik.values.sentEmailCode,
    });

    if (response.error) {
      response.error.data.errors.map((error) => {
        switch (error.code) {
          default:
            setVerificationCodeError(error.message);
            break;
        }
      });
    } else {
      dispatch(setFinishedRegistrationModal(AuthType.USER));
    }
  };

  const onClickSendEmailCode = async () => {
    const response = await requestEmailVerificationCode({
      email: userDataStepOne?.email,
    });
    if (response.error) {
      response.error.data.errors.map((error) => {
        switch (error.code) {
          default:
            setVerificationCodeError(error.message);
            break;
        }
      });
    } else {
      dispatch(setSentCodeModal(AuthType.USER));
    }
  };

  const formik = useFormik({
    initialValues: UserStepThreeAtributes,
    validationSchema: UserStepThreeValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <>
      <UpperContainer>
        <InputFieldsContainer>
          <SentCodeMessageContainer>
            {t("code.sentEmailMessage")}
          </SentCodeMessageContainer>
          <InputFieldContainer>
            <SentCodeField
              formik={formik}
              required
              helperText={verificationCodeError}
              clearErrorMessage={clearVerificationCodeErrorMessage}
            />
          </InputFieldContainer>
          <SentCodeBtnContainer>
            <SendNewCodeButton
              onClick={onClickSendEmailCode}
              colorPalet={userColors}
            />
          </SentCodeBtnContainer>
        </InputFieldsContainer>
      </UpperContainer>
      <LowerRegContent
        lastStep={true}
        onClickNextBtn={formik.handleSubmit}
        onClickBackBtn={props.onClickBackBtn}
        currentStep={props.currentStep}
        colorPalet={userColors}
      />
    </>
  );
};

UserStepThree.propTypes = {
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
  currentStep: PropTypes.number,
  theme: PropTypes.any,
};

export default UserStepThree;
