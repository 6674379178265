import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Overlay,
  Modal,
  Title,
  ActionsContainer,
  ActionButton,
  CancelActionButton,
  UpperContainer,
  LowerContainer,
} from "./ServiceCategoryEditAddModal.styled";
import {
  InputFieldContainer,
  InputFieldsContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import { VARIANT } from "constants/buttonConstants";
import NameField from "./InputFields/NameField";
import DescriptionField from "./InputFields/DescriptionField";
import { useFormik } from "formik";
import { serviceCategoryParams } from "initialValues/entityFormikParams/serviceCategoryFormikParams";
import { addEditSCvalidation } from "validations/serviceCategory/serviceCategoryValidation";
import {
  useAddServiceCategoryMutation,
  useUpdateServiceCategoryMutation,
} from "features/serviceCategory/serviceCategorySlice";
import { NameAlreadyInUse } from "constants/registrationBEerrors";
import { makeToastMessage } from "util/toastMessage";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";

const ServiceCategoryEditAddModal = ({ serviceCategory }) => {
  const { t } = useTranslation();
  const [addServiceCategory] = useAddServiceCategoryMutation();
  const [updateServiceCategory] = useUpdateServiceCategoryMutation();
  const [nameNotUniqueError, setNameNotUniqueError] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const data = {
      name: formik.values.name,
      code: "code",
      description: formik.values.description,
    };
    let response = null;
    if (serviceCategory) {
      response = await updateServiceCategory({
        id: serviceCategory?.id,
        data,
      });
    } else {
      response = await addServiceCategory({
        ...data,
      });
    }
    if (response?.error) {
      response.error.data.errors.map((error) => {
        switch (error.code) {
          case NameAlreadyInUse:
            setNameNotUniqueError(error.message);
            break;
          default:
            alert(error.message);
            break;
        }
      });
    } else {
      makeToastMessage(
        serviceCategory
          ? t("superAdmin.table.serviceCategories.successfullyUpdated")
          : t("superAdmin.table.serviceCategories.successfullyAdded")
      );
      dispatch(unsetShownModal());
    }
  };
  const editFlag = useMemo(() => serviceCategory != null, [serviceCategory]);
  const formik = useFormik({
    initialValues: serviceCategoryParams(serviceCategory),
    validationSchema: addEditSCvalidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const clearNameErrorMessage = () => {
    if (nameNotUniqueError != null) {
      setNameNotUniqueError(null);
    }
  };

  return (
    <Overlay>
      <Modal>
        <UpperContainer>
          <Title>
            {editFlag
              ? t("serviceCategory.editServiceCategory")
              : t("serviceCategory.addNewServiceCategory")}
          </Title>
          <InputFieldsContainer>
            <InputFieldContainer>
              <NameField
                formik={formik}
                helperText={nameNotUniqueError}
                clearErrorMessage={clearNameErrorMessage}
              />
            </InputFieldContainer>
            <InputFieldContainer>
              <DescriptionField formik={formik} />
            </InputFieldContainer>
          </InputFieldsContainer>
        </UpperContainer>
        <LowerContainer>
          <ActionsContainer>
            <CancelActionButton
              variant={VARIANT.OUTLINED}
              onClick={() => dispatch(unsetShownModal())}
              value={t(`common.cancel`)}
            />
            <ActionButton
              onClick={formik.handleSubmit}
              value={editFlag ? t(`common.save`) : t(`common.add`)}
            />
          </ActionsContainer>
        </LowerContainer>
      </Modal>
    </Overlay>
  );
};

ServiceCategoryEditAddModal.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onAction: PropTypes.func,
  item: PropTypes.array,
  type: PropTypes.string,
  name: PropTypes.string,
  formik: PropTypes.any,
  width: PropTypes.string,
  helperText: PropTypes.string,
  clearErrorMessage: PropTypes.func,
  selectedItem: PropTypes.object,
  serviceCategory: PropTypes.object,
};

ServiceCategoryEditAddModal.defaultProps = {};

export default ServiceCategoryEditAddModal;
