import MainLayout from "layouts/MainLayout";
import React from "react";

const SettingsPage = () => {
  return (
    <MainLayout>
      <p>Settings page</p>
    </MainLayout>
  );
};

export default SettingsPage;
