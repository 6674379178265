import React from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PAGES } from "constants/pages";
import loginValidation from "validations/loginValidation";
import loginInitialValues from "initialValues/loginInitialValues";
import EmailField from "components/InputFields/EmailField";
import PasswordField from "components/InputFields/PasswordField";
import OrDivider from "components/OrDivider/OrDivider";
import {
  RightAuthContainer,
  LowerContainer,
  ContentContainer,
  InputFieldsContainer,
  InputFieldContainer,
  ActionsContainer,
  LogTitleContainer,
  JustifiedRightItems,
  LogRegLinkStyled,
  FirstText,
  BottomTextContainer,
  ForgotPWLinkStyled,
  OrDividerGoogleBtnContainer,
  UpperContainerLogIn,
  StyledTypography,
} from "components/RightAuthContent/RightAuthContent.styled";
import { LogInButton } from "./LogInButton/LogInButton";
import GoogleButton from "components/RightAuthContent/Buttons/GoogleButton/GoogleButton";
import { useLoginMutation } from "features/auth/authApiSlice";
import { Logo } from "../Header/Header.styled";

import { makeErrorToastMessage } from "util/toastMessage";

import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { decodeToken } from "react-jwt";
import { TEXTVARIANT } from "constants/textConstants";

const LogInContent = () => {
  const [login] = useLoginMutation();
  const { t } = useTranslation();

  const handleGoogle = () => {};

  const handleSubmit = async () => {
    const response = await login(formik.values);
    if (response.data?.isSuccess) {
      const { accessToken, refreshToken } = response.data.data;
      const decodedToken = decodeToken(accessToken);

      const user = {
        id: decodedToken.id,
        email: decodedToken.email,
        firstName: decodedToken.firstName,
        lastName: decodedToken.lastName,
        role: decodedToken.role,
        expireAt: decodedToken.exp,
        issuedAt: decodedToken.iat,
        issuer: decodedToken.iss,
        audience: decodedToken.aud,
      };

      dispatch(setCredentials({ accessToken, refreshToken, user }));
    } else if (response.error?.data?.isFailure) {
      makeErrorToastMessage(
        `Login failed! ${response.error.data.errors[0].message}`
      );
    } else {
      makeErrorToastMessage(`Login failed! ${response.error.message}`);
    }
  };

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <RightAuthContainer>
      <LogTitleContainer>
        <Logo />
        <StyledTypography variant={TEXTVARIANT.H1}>
          {t("login.logInTitle")}
        </StyledTypography>
      </LogTitleContainer>
      <ContentContainer>
        <UpperContainerLogIn>
          <InputFieldsContainer>
            <InputFieldContainer>
              <EmailField formik={formik} required />
            </InputFieldContainer>
            <InputFieldContainer>
              <PasswordField formik={formik} required />
            </InputFieldContainer>
          </InputFieldsContainer>
          <JustifiedRightItems>
            <ForgotPWLinkStyled
              to={PAGES.FORGOT_PASSWORD.route}
              component={NavLink}
            >
              {t("forgotPassword.title") + "?"}
            </ForgotPWLinkStyled>
          </JustifiedRightItems>
          <ActionsContainer>
            <LogInButton onClick={formik.handleSubmit} />
            <OrDividerGoogleBtnContainer>
              <OrDivider />
              <GoogleButton onClick={handleGoogle} />
            </OrDividerGoogleBtnContainer>
          </ActionsContainer>
        </UpperContainerLogIn>
        <LowerContainer>
          <BottomTextContainer>
            <FirstText>{t("login.dontHaveAccount")}</FirstText>
            <LogRegLinkStyled
              to={PAGES.MIDDLEPAGE.route}
              component={NavLink}
              color={(props) => props?.theme?.colors?.leftAuthContentBackground}
            >
              {t("register.registerTitle")}
            </LogRegLinkStyled>
          </BottomTextContainer>
        </LowerContainer>
      </ContentContainer>
    </RightAuthContainer>
  );
};

LogInContent.propTypes = {
  theme: PropTypes.any,
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
export default LogInContent;
