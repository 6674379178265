export default {
  serviceCategories: {
    getAll: "service-categories",
    getPaginated:
      "/service-categories?pageNumber={pageNumber}&pageSize={pageSize}",
    create: "service-categories",
    update: "service-categories/{id}",
  },
  clientLicenses: {
    getAll: "client-licenses",
    getPaginated: "client-licenses?pageNumber={pageNumber}&pageSize={pageSize}",
    updateLicence: "client-licenses/{id}",
    addLicence: "client-licenses",
  },
  clients: {
    getPaginated: (pageNumber, pageSize, filters = {}) => {
      const { name, status, location, serviceCategory } = filters;

      const params = new URLSearchParams({
        pageNumber: pageNumber,
        pageSize: pageSize,
        ...(name && { "clientFilters.name": name }),
        ...(status && { "clientFilters.status": status }),
        ...(location && { "clientFilters.location": location }),
        ...(serviceCategory && {
          "clientFilters.serviceCategory": serviceCategory,
        }),
      });

      const url = `/clients?${params.toString()}`;
      return url;
    },
  },
  forgotPassword: {
    forgotPassword: "login/forgot-password",
    resetPasswrod: "login/reset-password?id={id}&token={token}",
  },
  authentication: {
    login: "login",
    refreshToken: "login/refresh",
    logout: "logout",
  },
  users: {
    getUser: "users/{id}",
    getAll: "users",
    getPaginated: (pageNumber, pageSize, filters = {}) => {
      const { firstName, lastName, email, city, status } = filters;

      const params = new URLSearchParams({
        pageNumber: pageNumber,
        pageSize: pageSize,
        ...(firstName && { "userFilters.firstName": firstName }),
        ...(lastName && { "userFilters.lastName": lastName }),
        ...(email && { "userFilters.email": email }),
        ...(city && {
          "userFilters.city": city,
        }),
        ...((status === true || status === false) && {
          "userFilters.status": status,
        }),
      });

      const url = `/users?${params.toString()}`;
      return url;
    },
    changeStatus: "users/change-status/{id}",
  },
  registration: {
    checkEmail: "/users/check-email/{email}",
    addPendingApplicationUser: "/users/add-pending-application-user",
    requestEmailVerificationCode: "/users/request-email-verification-code",
    verifyEmail: "/users",
    checkRegistrationName: "clients/check-registration-name/{regName}",
    register: "clients",
  },
};
