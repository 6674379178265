import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import forgotPasswordValidation from "validations/forgotPasswordValidation";
import EmailField from "components/InputFields/EmailField";
import { useFormik } from "formik";
import { useForgotPasswordMutation } from "features/forgotPassword/forgotPasswordSlice";
import { VARIANT } from "constants/buttonConstants";
import { PAGES } from "constants/pages";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [useForgotPassword] = useForgotPasswordMutation();
  const handleSubmit = async () => {
    const response = await useForgotPassword({
      email: formik.values.email,
      redirectionUrl:
        process.env.REACT_APP_BASE_URL + PAGES.RESET_PASSWORD.route,
    });
    if (response.error) {
      response.error.data.errors.map((error) => {
        switch (error.code) {
          default:
            alert(error.message);
            break;
        }
      });
    } else {
      alert(t("login.forgotPasswordEmailSent"));
    }
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: forgotPasswordValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Box sx={{ display: "flex", gap: "25px" }}>
      <EmailField formik={formik} required />
      <Button variant={VARIANT.OUTLINED} type="submit" onClick={handleSubmit}>
        {t("forgotPassword.label")}
      </Button>
    </Box>
  );
};

export default ForgotPasswordPage;
