import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/SuperAdmin/Buttons/ActionButton/ActionButton";
import { ReactComponent as PlusIcon } from "assets/images/svg/update-table-row-icon.svg";

const UpdateRenderer = (props) => {
  return (
    <ActionButton
      startIcon={<PlusIcon />}
      value={"common.edit"}
      onClick={props.onClick}
    />
  );
};
UpdateRenderer.propTypes = {
  onClick: PropTypes.func,
};

export default UpdateRenderer;
